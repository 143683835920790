import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player/vimeo";

import { VideoContainer, PlayC, Controls, Prev } from "./VideoPlayer.styles";
import Duration from "./Duration";
import { Play } from "components/icons";
import { white } from "theme/colors";

const VimeoPlayer = (props) => {
  //PROPS
  const { defaultVimeoOptions, id, videoID, setVideoID } = props;
  //PROPS

  return (
    <VideoContainer>
      <ReactPlayer
        className="react-player"
        url={`https://vimeo.com/${id}`}
        width="100%"
        height="100%"
        onPlay={() => {
          setVideoID(id);
        }}
        onPause={() => {
          if (videoID === id) {
            setVideoID(null);
          }
        }}
        playing={videoID == id}
        controls={defaultVimeoOptions.controls}
        volume={defaultVimeoOptions.volume}
        muted={defaultVimeoOptions.muted}
      />
    </VideoContainer>
  );
};

const Dual = (props) => {
  //PROPS
  const {
    defaultVimeoOptions,
    id,
    videoID,
    setVideoID,
    onHover,
    preview,
    isMulti,
    videoIndex,
    slideIndex,
    isIn,
    isLoad,
    poster
  } = props;
  //PROPS

  //STATE
  const [video, setVideo] = useState({
    playing: false,
    duration: 0
  });
  //STATE

  //REF
  const thePreview = useRef(null);
  //REF

  //EFFECT
  useEffect(() => {
    if (videoID === id) {
      setVideo({ ...video, playing: true });
    } else {
      setVideo({ ...video, playing: false });
    }
  }, [videoID]);

  useEffect(() => {
    if (isIn && !video.playing && !onHover && !isMulti) {
      thePreview.current.play();
    } else if (!isIn && !isMulti) {
      thePreview.current.pause();
    }
  }, [isIn, video.playing]);

  useEffect(() => {
    if (isMulti) {
      setVideo({
        ...video,
        playing: false
      });

      videoIndex === slideIndex && isIn
        ? thePreview.current.play()
        : thePreview.current.pause();
    }
    setVideoID(null);
  }, [isIn, slideIndex]);

  // console.log(poster);
  //EFFECT

  return (
    <VideoContainer>
      <Prev
        className={videoID !== id ? "" : "prevc--hide"}
        onMouseEnter={() => {
          if (onHover) {
            thePreview.current.play();
          }
        }}
        onMouseLeave={() => {
          if (onHover) {
            thePreview.current.pause();
          }
        }}
      >
        <video
          playsInline
          poster={poster}
          autoPlay={false}
          loop
          muted={true}
          ref={thePreview}
          className="prev"
          onClick={() => setVideoID(id)}
          src={isLoad ? preview : ""}
        />

        <Controls className="videoControls">
          <div className="col-left">
            <PlayC onClick={() => setVideoID(id)} aria-label="Play video">
              <Play color={white.default} />
            </PlayC>
            {video.duration !== 0 && <Duration seconds={video.duration} />}
          </div>
        </Controls>
      </Prev>

      {isLoad && (
        <ReactPlayer
          className="react-player"
          url={`https://vimeo.com/${id}`}
          width="100%"
          height="100%"
          onDuration={(e) => setVideo({ ...video, duration: e })}
          onPlay={() => {
            setVideoID(id);
          }}
          onPause={() => {
            if (videoID === id) {
              setVideoID(null);
            }
          }}
          playing={videoID === id}
          controls={defaultVimeoOptions.controls}
          volume={defaultVimeoOptions.volume}
          muted={defaultVimeoOptions.muted}
        />
      )}
    </VideoContainer>
  );
};

const PreviewPlayer = (props) => {
  //PROPS
  const {
    preview,
    onHover,
    isLoad,
    isIn,
    isMulti,
    videoIndex,
    slideIndex
  } = props;
  //PROPS

  //REF
  const thePreview = useRef(null);
  //REF

  //EFFECT
  useEffect(() => {
    if (isIn && !onHover && !isMulti) {
      thePreview.current.play();
    } else if (!isIn && !isMulti) {
      thePreview.current.pause();
    }
  }, [isIn]);

  useEffect(() => {
    if (isMulti) {
      videoIndex === slideIndex && isIn
        ? thePreview.current.play()
        : thePreview.current.pause();
    }
  }, [isIn, slideIndex]);
  //EFFECT

  return (
    <VideoContainer>
      <Prev
        onMouseEnter={() => {
          if (onHover) {
            thePreview.current.play();
          }
        }}
        onMouseLeave={() => {
          if (onHover) {
            thePreview.current.pause();
          }
        }}
      >
        <video
          playsInline
          autoPlay={false}
          loop
          muted={true}
          src={isLoad ? preview : ""}
          ref={thePreview}
          className="prev"
        />
      </Prev>
    </VideoContainer>
  );
};

const VideoPlayer = (props) => {
  //PROPS
  const {
    isMulti,
    videoIndex,
    slideIndex,
    onHover,
    isLoad,
    id,
    preview,
    videoID,
    setVideoID,
    isIn,
    poster
  } = props;
  //PROPS

  //STATE
  const [type, setType] = useState("");
  const defaultVimeoOptions = {
    controls: true,
    volume: 0.2,
    muted: false
  };
  //STATE

  //USEEFFECT

  useEffect(() => {
    if (id && !preview) {
      setType("vimeo");
    } else if (!id && preview) {
      setType("preview");
    } else if (preview && id) {
      setType("dual");
    }
  }, []);

  //USEEFFECT

  switch (type) {
    case "vimeo":
      return (
        <VimeoPlayer
          defaultVimeoOptions={defaultVimeoOptions}
          id={id}
          videoID={videoID}
          setVideoID={setVideoID}
        ></VimeoPlayer>
      );
    case "dual":
      return (
        <Dual
          poster={poster}
          defaultVimeoOptions={defaultVimeoOptions}
          id={id}
          onHover={onHover}
          preview={preview}
          videoID={videoID}
          setVideoID={setVideoID}
          isMulti={isMulti}
          videoIndex={videoIndex}
          slideIndex={slideIndex}
          isIn={isIn}
          isLoad={isLoad}
        ></Dual>
      );
    case "preview":
      return (
        <PreviewPlayer
          preview={preview}
          onHover={onHover}
          isLoad={isLoad}
          isIn={isIn}
          isMulti={isMulti}
          videoIndex={videoIndex}
          slideIndex={slideIndex}
        ></PreviewPlayer>
      );
    default:
      return "uups";
  }
};

export default VideoPlayer;
