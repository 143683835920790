import styled from "styled-components";

import { p } from "theme/typography";
import { white } from "theme/colors";
import { toRem } from "utils/mixins";

export const VideoContainer = styled.div`
  position: ${(props) => (props.fullscreen ? "fixed" : "relative")};
  width: ${(props) => (props.fullscreen ? "100vw" : "100%")};
  top: 0;
  left: 0;
  overflow: hidden;
  height: ${(props) => (props.fullscreen ? "100vh" : "0")};
  padding-top: ${(props) => (props.fullscreen ? "0" : " 56.25%")};
  background-color: black;

  z-index: ${(props) => (props.fullscreen ? "5000" : "1")};
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover .videoControls {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

export const Prev = styled.div`
  display: block;
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  transition: all 250ms;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
  }
  &.prevc--hide {
    pointer-events: none;
    opacity: 0;
  }
`;

export const PlayC = styled.button`
  position: relative;
  display: block;
  height: ${toRem(12)};
  width: auto;
  color: ${white.default};
  svg {
    margin: 0;
    display: block;
    height: ${toRem(12)};
    width: auto;
  }
`;

export const FullscreenC = styled.button`
  position: relative;
  display: block;
  height: ${toRem(12)};
  width: auto;
  svg {
    margin: 0;
    display: block;
    height: 100%;
    width: auto;
  }
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 0px;
  top: unset !important;
  width: 100%;
  display: flex;
  height: ${toRem(30)};
  align-items: center;
  justify-content: flex-start;
  color: ${white.default};
  z-index: 3;
  transition: 250ms;

  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  .col-left {
    padding: ${toRem(15)};
    display: flex;
    align-items: center;
    justify-content: center;
    .time {
      display: block;
      position: relative;
      color: ${white.default};
      ${p}
      line-height: 0.8!important;
      margin: 0;
      margin-left: ${toRem(15)};
    }
  }
  @media (min-width: 1024px) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    height: ${toRem(50)};
  }
`;
