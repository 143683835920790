import React from "react";

const Duration = (props) => {
  //PROPS
  const { seconds } = props;
  //PROPS

  //FUNCS
  function format(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  function pad(string) {
    return ("0" + string).slice(-2);
  }
  //FUNCS

  return (
    <span dateTime={`P${Math.round(seconds)}S`} className="time">
      {format(seconds)}
    </span>
  );
};

export default Duration;
